import { render, staticRenderFns } from "./foodBdCPay7.vue?vue&type=template&id=c305df90&scoped=true"
import script from "./foodBdCPay7.vue?vue&type=script&lang=js"
export * from "./foodBdCPay7.vue?vue&type=script&lang=js"
import style0 from "./foodBdCPay7.vue?vue&type=style&index=0&id=c305df90&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c305df90",
  null
  
)

export default component.exports